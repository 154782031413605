import { PulseLoader } from "react-spinners";
import IButton from "./IButton";

import styles from "./styles.module.css";

const Button = ({
  className,
  text,
  onClick,
  style,
  textStyle,
  gradient,
  disabled,
  loading,
  ...rest
}: IButton) => (
  <button
    className={`${className} ${styles.container} ${
      gradient ? styles.gradient : ""
    } ${disabled ? styles.disabled : ""}`}
    onClick={!disabled ? onClick : undefined}
    style={style}
    {...rest}
  >
    <span className={styles.text} style={textStyle}>
      {loading ? <PulseLoader color="white" loading /> : text}
    </span>
  </button>
);

export default Button;
