interface IImage {
  src: string | undefined;
  alt: string;
  width?: number;
  height?: number;
  className?: string;
  fill?: boolean;
  onClick?: () => void;
  style?: any;
}

const Image = ({
  src,
  alt,
  width,
  height,
  className,
  fill,
  onClick,
  style,
}: IImage) => {
  return (
    <img
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={className}
      style={{
        ...style,
        width: fill ? "100%" : undefined,
        height: fill ? "100%" : undefined,
      }}
      onClick={onClick}
    />
  );
};

export default Image;
