import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./screens";
import "./translations/i18n";

const App = () => (
  <BrowserRouter>
    <div style={{ backgroundColor: "#15191f", minHeight: "100vh" }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </div>
  </BrowserRouter>
);

export default App;
