import { useCallback, useState } from "react";
import Button from "../Button";
import { Tooltip } from "react-tooltip";

import styles from "./styles.module.css";
import { PopupModal } from "react-calendly";
import Image from "../Image";
import { Underline } from "../../assets/images";

const ContactUs = ({ contactRef }: { contactRef?: any }) => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    const data = new FormData();
    data.append("Nombre", form.name);
    data.append("Email", form.email);
    data.append("Numero de Telefono", form.phone);
    data.append("Empresa", form.company);
    data.append("Mensaje", form.message);

    const Sheet_Url = process.env.REACT_APP_API_LINK || "";

    console.log({ Sheet_Url });

    try {
      await fetch(Sheet_Url, {
        method: "POST",
        body: data,
      });

      setIsOpen(true);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [
    form.company,
    form.email,
    form.message,
    form.name,
    form.phone,
    setIsOpen,
    setLoading,
  ]);

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  return (
    <section className={styles.contactUs} data-aos="fade-up" ref={contactRef}>
      <h1 className={styles.contactUsTitle}>Contáctanos</h1>
      <Image
        src={Underline}
        alt="Underline"
        width={476}
        height={17}
        className={styles.underline}
      />
      <form className={styles.form} onSubmit={(e) => e.preventDefault()}>
        <label className={styles.label} htmlFor="name">
          Nombre *
        </label>
        <input
          name="name"
          type="text"
          placeholder="Nombre"
          className={styles.textInput}
          required
          autoCapitalize="words"
          onChange={(e) => setForm({ ...form, name: e.target.value })}
          value={form.name}
        />
        <label className={styles.label} htmlFor="email">
          Email *
        </label>
        <input
          name="email"
          type="email"
          placeholder="Email"
          className={styles.textInput}
          required
          autoCapitalize="none"
          onChange={(e) => setForm({ ...form, email: e.target.value })}
          value={form.email}
        />
        <label className={styles.label} htmlFor="phone">
          Número de teléfono *
        </label>
        <input
          name="phone"
          type="tel"
          placeholder="Número de teléfono"
          className={styles.textInput}
          required
          onChange={(e) => setForm({ ...form, phone: e.target.value })}
          value={form.phone}
        />
        <label className={styles.label} htmlFor="company">
          Empresa *
        </label>
        <input
          name="company"
          type="text"
          placeholder="Empresa"
          className={styles.textInput}
          required
          autoCapitalize="words"
          onChange={(e) => setForm({ ...form, company: e.target.value })}
          value={form.company}
        />
        <label className={styles.label} htmlFor="message">
          Mensaje
        </label>
        <textarea
          name="message"
          placeholder="Mensaje"
          className={styles.textArea}
          rows={9}
          autoCapitalize="sentences"
          onChange={(e) => setForm({ ...form, message: e.target.value })}
          value={form.message}
        />
        <Button
          data-tooltip-id="error-tooltip"
          data-tooltip-content={
            !form.name || !form.name || !form.phone || !form.company
              ? "Please fill out all required fields"
              : "Please enter a valid email"
          }
          text="Enviar"
          loading={loading}
          style={{ fontSize: 20, padding: "16px 32px" }}
          gradient
          disabled={
            !form.name ||
            !form.name ||
            !validateEmail(form.email) ||
            !form.phone ||
            !form.company
          }
          onClick={onSubmit}
        />
        {(!form.name ||
          !form.name ||
          !validateEmail(form.email) ||
          !form.phone ||
          !form.company) && <Tooltip id="error-tooltip" />}
      </form>
      <PopupModal
        url="https://calendly.com/brunopintos/30min"
        pageSettings={{
          backgroundColor: "ffffff",
          hideEventTypeDetails: false,
          primaryColor: "00a2ff",
          textColor: "4d5055",
        }}
        utm={{
          utmCampaign: "Spring Sale 2019",
          utmContent: "Shoe and Shirts",
          utmMedium: "Ad",
          utmSource: "Facebook",
          utmTerm: "Spring",
        }}
        prefill={{
          name: form.name,
          email: form.email,
          smsReminderNumber: form.phone,
        }}
        onModalClose={() => {
          setForm({
            name: "",
            email: "",
            phone: "",
            company: "",
            message: "",
          });
          setIsOpen(false);
        }}
        open={isOpen}
        rootElement={contactRef.current}
      />
    </section>
  );
};

export default ContactUs;
