import { Hiw1, Hiw2, Hiw3, Underline } from "../../assets/images";
import Image from "../Image";
import styles from "./styles.module.css";

const steps = [
  {
    title: "Publicación en segundos",
    description:
      "Selecciona tu entrada desde tu galería o sube el pdf original, elige el precio de venta y nosotros nos encargamos del resto.",
    image: Hiw1,
  },
  {
    title: "Venta sin complicaciones",
    description:
      "Una vez publicado tu anuncio, los usuarios podrán comprar tu entrada y recibirás el pago al instante.",
    image: Hiw2,
  },
  {
    title: "Compra 100% segura",
    description:
      "Al momento de la compra, se genera una entrada totalmente nueva y única para el comprador, y la entrada original que estaba a la venta se invalida.",
    image: Hiw3,
  },
];

const HowItWorks = () => {
  return (
    <section className={styles.howItWorks} data-aos="fade-up">
      <h1 className={styles.howItWorksTitle}>Cómo funciona?</h1>
      <Image
        src={Underline}
        alt="Underline"
        width={476}
        height={17}
        className={styles.underline}
      />
      <div className={styles.stepsContainer}>
        {steps.map((step, index) => (
          <div className={styles.stepContainer} key={step.title}>
            <div className={styles.imageContainer}>
              <div className={styles.image}>
                <Image
                  src={step.image}
                  alt={step.title}
                  fill
                  style={{ objectFit: "contain" }}
                />
              </div>
            </div>
            <div className={styles.stepContent}>
              <h2 className={styles.stepTitle}>{step.title}</h2>
              <p className={styles.stepDescription}>{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HowItWorks;
