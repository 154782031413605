import {
  Bars,
  Cines,
  Concerts,
  Discos,
  Festivals,
  Sports,
  Theaters,
} from "../../assets/images";
import Image from "../Image";
import styles from "./styles.module.css";

const carouselItems = [
  { text: "Bares", icon: Bars },
  { text: "Deportes", icon: Sports },
  { text: "Conciertos", icon: Concerts },
  { text: "Boliches", icon: Discos },
  { text: "Teatro", icon: Theaters },
  { text: "Festivales", icon: Festivals },
  { text: "Cine", icon: Cines },
];

const Events = () => (
  <section className={styles.industriesWeServe} data-aos="fade-up">
    <h1 className={styles.industriesWeServeTitle}>Eventos que cubrimos</h1>
    <p className={styles.industriesWeServeText}>
      Desde conciertos hasta deportes, revende entradas de forma segura para una
      variedad de eventos...
    </p>
    <div className={styles.carousel}>
      <div className={styles.carousel__wrapper}>
        {carouselItems.map((item) => (
          <div key={item.text} className={styles.carousel__slide}>
            <div className={styles.carousel__image}>
              <Image
                src={item.icon}
                alt={item.text}
                fill
                style={{ objectFit: "contain" }}
              />
            </div>
            <div className={styles.carousel__text}>{item.text}</div>
          </div>
        ))}
        {/* Repeating */}
        {carouselItems.map((item) => (
          <div key={`2nd${item.text}`} className={styles.carousel__slide}>
            <div className={styles.carousel__image}>
              <Image
                src={item.icon}
                alt={item.text}
                fill
                style={{ objectFit: "contain" }}
              />
            </div>
            <div className={styles.carousel__text}>{item.text}</div>
          </div>
        ))}
        {/* Repeating */}
        {carouselItems.map((item) => (
          <div key={`2nd${item.text}`} className={styles.carousel__slide}>
            <div className={styles.carousel__image}>
              <Image
                src={item.icon}
                alt={item.text}
                fill
                style={{ objectFit: "contain" }}
              />
            </div>
            <div className={styles.carousel__text}>{item.text}</div>
          </div>
        ))}
        {/* Repeating */}
        {carouselItems.map((item) => (
          <div key={`2nd${item.text}`} className={styles.carousel__slide}>
            <div className={styles.carousel__image}>
              <Image
                src={item.icon}
                alt={item.text}
                fill
                style={{ objectFit: "contain" }}
              />
            </div>
            <div className={styles.carousel__text}>{item.text}</div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Events;
