import { Link } from "react-router-dom";
import {
  Animation,
  AppStore,
  GooglePlay,
  PhoneBackground,
} from "../../assets/images";
import Image from "../Image";

import styles from "./styles.module.css";

const Intro = () => {
  return (
    <section className={styles.intro} data-aos="fade-up">
      <div className={styles.leftContainer}>
        <h1 className={styles.introTitle}>
          Comprar y vender entradas nunca fue tan{" "}
          <span className={styles.gradientText}>fácil</span> y{" "}
          <span className={styles.gradientText}>seguro.</span>
        </h1>
        <h2 className={styles.introSubtitle}>
          Con <span className={styles.orangeText}>TicketTwist</span> la
          seguridad de tu entrada está garantizada, quedate tranquilo que nunca
          te quedas afuera ;)
        </h2>
        <div className={styles.buttonsContainer}>
          <Link to="/">
            <Image
              className={styles.storeButton}
              src={AppStore}
              alt="App Store"
            />
          </Link>
          <Link to="/">
            <Image
              className={styles.storeButton}
              src={GooglePlay}
              alt="Google Play"
            />
          </Link>
        </div>
      </div>
      <div className={styles.rightContainer}>
        <Image
          src={PhoneBackground}
          alt="Phone Background"
          className={styles.phoneBackground}
        />
        <div className={styles.image}>
          <Image src={Animation} alt="TicketTwist Animation" fill />
        </div>
      </div>
    </section>
  );
};

export default Intro;
