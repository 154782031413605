import styles from "./styles.module.css";
import Image from "../Image";
import {
  Facebook,
  FooterEffect,
  FullLogo,
  Instagram,
  LinkedIn,
  X,
  Youtube,
} from "../../assets/images";
import dayjs from "dayjs";

const socialMedia = [
  { name: "Facebook", image: Facebook },
  { name: "Instagram", image: Instagram },
  { name: "X", image: X },
  { name: "LinkedIn", image: LinkedIn },
  { name: "Youtube", image: Youtube },
];

const Footer = ({ onLearnMoreClick }: { onLearnMoreClick: () => void }) => {
  return (
    <footer className={styles.footer}>
      <Image
        src={FooterEffect}
        alt="Footer Effect"
        fill
        className={styles.footerEffect}
      />
      <div className={styles.contentContainer}>
        <div className={styles.topContainer}>
          <div className={styles.companyContainer}>
            <div className={styles.imageContainer}>
              <Image src={FullLogo} alt="TicketTwist Logo" fill />
            </div>
            <p className={styles.companyDescription}>
              Transforma la forma en que compras y vendes entradas! Con
              TicketTwist cada transacción es{" "}
              <b className={styles.bold}>rápida, segura y sin complicaciones</b>
              .
            </p>
          </div>
        </div>
        <div className={styles.bottomContainer}>
          <p className={styles.rightsReserved}>
            © {dayjs().format("YYYY")} TicketTwist. Todos los derechos
            reservados.
          </p>
          <div className={styles.socialMediaContainer}>
            {socialMedia.map((social) => (
              <div className={styles.socialMediaIcon} key={social.name}>
                <Image
                  key={social.name}
                  src={social.image}
                  alt={social.name}
                  fill
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
