import { FullLogo } from "../../assets/images";
import Button from "../Button";
import Image from "../Image";
import IHeader from "./IHeader";

import styles from "./styles.module.css";

const Header = ({ left, right, onMoreInfo, onLogo }: IHeader) => (
  <header className={styles.container}>
    <div className={styles.content}>
      {left || (
        <Image
          src={FullLogo}
          alt="TicketTwist Logo"
          onClick={onLogo}
          className={styles.logo}
        />
      )}
      {right || (
        <Button
          className={styles.animatedButton}
          text={window.innerWidth <= 768 ? "Más info" : "Más información"}
          onClick={onMoreInfo}
        />
      )}
    </div>
  </header>
);

export default Header;
