"use client";

import styles from "./styles.module.css";
import { useRef, useState } from "react";
import { PopupModal } from "react-calendly";
import Header from "../../components/Header";
import Intro from "../../components/Intro";
import Events from "../../components/Events";
import HowItWorks from "../../components/HowItWorks";
import DidntReinvent from "../../components/DidntReinvent";
import ContactUs from "../../components/ContactUs";
import Footer from "../../components/Footer";

const Home = () => {
  const contactRef = useRef<HTMLDivElement | null>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const onContactClick = () => {
    const position = contactRef?.current?.offsetTop || 0;
    window?.scrollTo({ top: position - 20, behavior: "smooth" });
  };

  const onLogo = () => {
    window?.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <main className={styles.main} ref={scrollRef}>
      <Header onMoreInfo={onContactClick} onLogo={onLogo} />
      <div className={styles.contentContainer}>
        <Intro />
        <Events />
        <HowItWorks />
      </div>
      <DidntReinvent />
      <div className={styles.contentContainer}>
        <ContactUs contactRef={contactRef} />
      </div>
      <Footer onLearnMoreClick={onContactClick} />
      {scrollRef.current && (
        <PopupModal
          url="https://calendly.com/brunopintos/30min"
          pageSettings={{
            backgroundColor: "ffffff",
            hideEventTypeDetails: false,
            primaryColor: "00a2ff",
            textColor: "4d5055",
          }}
          utm={{
            utmCampaign: "Spring Sale 2019",
            utmContent: "Shoe and Shirts",
            utmMedium: "Ad",
            utmSource: "Facebook",
            utmTerm: "Spring",
          }}
          onModalClose={() => {
            setIsOpen(false);
          }}
          open={isOpen}
          rootElement={scrollRef.current}
        />
      )}
    </main>
  );
};

export default Home;
