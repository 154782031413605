import { BackgroundReinvent } from "../../assets/images";
import Image from "../Image";
import styles from "./styles.module.css";

const DidntReinvent = () => (
  <section data-aos="fade-up" className={styles.didntReinvent}>
    <Image
      src={BackgroundReinvent}
      alt="Background Reinvent"
      fill
      className={styles.background}
    />
    <h1 className={styles.didntReinventText}>
      No inventamos la reventa de entradas. Solo la hicimos más{" "}
      <span className={styles.violetText}>segura</span> y{" "}
      <span className={styles.violetText}>fácil</span> para todos.
    </h1>
  </section>
);

export default DidntReinvent;
